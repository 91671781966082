
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Home extends Vue {
  get isLogged () {
    if (this.$store.getters['global/getToken'] === null) {
      return false
    }
    return true
  }
}
