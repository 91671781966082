<template>
  <div >
    <h1>Back office ChaYall</h1>
    <router-link :to="{ name: 'login' }" v-if="isLogged === false">Login</router-link>
    <div v-else>
      <el-row :gutter="20">
        <el-col :offset="9" :span="3">
          <router-link to="customers" >
            <el-button type="primary">Customers</el-button>
          </router-link>
        </el-col>
        <el-col :span="3">
          <router-link to="users" >
            <el-button type="primary">Users</el-button>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Home extends Vue {
  get isLogged () {
    if (this.$store.getters['global/getToken'] === null) {
      return false
    }
    return true
  }
}
</script>
